var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ways"},[_c('div',{staticClass:"ways-title"}),(false)?_c('div',{staticClass:"setting-cont"},[_c('div',{staticClass:"setting-cont-list"},[_vm._m(0),_c('div',{staticClass:"list-cont"},[_vm._m(1),_c('div',{staticClass:"cont-she"},[_c('div',{staticClass:"cont-radio"},[(false)?_c('div',{staticClass:"cont-radio-checked"}):_vm._e()]),_c('span',[_vm._v("领养角色")])]),_c('div',{staticClass:"cont-she"},[_c('div',{staticClass:"cont-radio"},[(false)?_c('div',{staticClass:"cont-radio-checked"}):_vm._e()]),_vm._m(2)])]),_c('div',{staticClass:"list-input"},[_vm._m(3),_c('el-input',{staticClass:"name-input",attrs:{"placeholder":"请输入名字"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)]),_c('div',{staticClass:"setting-cont-list"},[_vm._m(4),_c('div',{staticClass:"list-cont"},[_vm._m(5),_c('div',{staticClass:"cont-big"},[_vm._m(6),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[(false)?_c('div',{staticClass:"cont-radio-checked"}):_vm._e()]),_vm._m(7)])])])]),_c('div',{staticClass:"setting-cont-list"},[_vm._m(8),_c('div',{staticClass:"list-cont"},[_vm._m(9),_c('div',{staticClass:"cont-big"},[_vm._m(10),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[(false)?_c('div',{staticClass:"cont-radio-checked"}):_vm._e()]),_vm._m(11)])])])]),_c('div',{staticClass:"setting-cont-list"},[_vm._m(12),_c('div',{staticClass:"list-cont"},[_vm._m(13),_c('div',{staticClass:"cont-big"},[_vm._m(14),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[(false)?_c('div',{staticClass:"cont-radio-checked"}):_vm._e()]),_vm._m(15)])])])]),_vm._m(16),_c('div',{staticClass:"setting-foot"},[_vm._v("下一页")])]):_vm._e(),_c('div',{staticClass:"setting-cont"},[_c('PrincipalInformation')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title"},[_vm._v("设定 "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-she active"},[_c('div',{staticClass:"cont-radio"},[_c('div',{staticClass:"cont-radio-checked"})]),_c('span',[_vm._v("自带设定")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("需委托设定"),_c('span',{staticStyle:{"color":"#49D6FF","font-size":"26px"}},[_vm._v("+500")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-txt"},[_vm._v("角色编码 或 角色名称"),_c('span',{staticStyle:{"color":"#E9B96B"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title"},[_vm._v("外壳版本 "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[_c('div',{staticClass:"cont-radio-checked"})]),_c('span',[_vm._v("标准外观 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("5000+")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"she-img"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("自由外观 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("5000+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title"},[_vm._v("耳机部件 "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[_c('div',{staticClass:"cont-radio-checked"})]),_c('span',[_vm._v("单色光片")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"she-img"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("彩色屏幕 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+300")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-title"},[_vm._v("表情点阵 "),_c('span',[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-radio"},[_c('div',{staticClass:"cont-radio-checked"})]),_c('span',[_vm._v("圆形")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"she-img"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("方形 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setting-cont-list"},[_c('div',{staticClass:"list-title"},[_vm._v("更多选配")]),_c('div',{staticClass:"list-cont",staticStyle:{"flex-wrap":"wrap"}},[_c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-checkbox"},[_c('img',{staticClass:"cont-checkbox-checked",attrs:{"src":require("../../assets/img/point.png"),"alt":""}})]),_c('span',[_vm._v("有角 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+200")])])])]),_c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-checkbox"},[_c('img',{staticClass:"cont-checkbox-checked",attrs:{"src":require("../../assets/img/point.png"),"alt":""}})]),_c('span',[_vm._v("两对耳朵 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+300")])])])]),_c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-checkbox"},[_c('img',{staticClass:"cont-checkbox-checked",attrs:{"src":require("../../assets/img/point.png"),"alt":""}})]),_c('span',[_vm._v("复杂花纹花色 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+500")])])])]),_c('div',{staticClass:"cont-big active"},[_c('div',{staticClass:"she-img"},[_c('div')]),_c('div',{staticClass:"she-choose"},[_c('div',{staticClass:"cont-checkbox"},[_c('img',{staticClass:"cont-checkbox-checked",attrs:{"src":require("../../assets/img/point.png"),"alt":""}})]),_c('span',[_vm._v("彩色屏幕动画 "),_c('span',{staticStyle:{"color":"#49D6FF"}},[_vm._v("+100")])])])])])])
}]

export { render, staticRenderFns }