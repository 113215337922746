<template>
  <!-- sult委托人信息 -->
  <div>
    <div class="order-list">
      <div class="order-top">
        <img src="../../assets/img/icon-order.png" alt="">
        <span>报价表</span>
      </div>
      <div class="order-cont">
        <div>
          <span>选定的内容01</span>
          <span style="color: #49D6FF;">￥5000+</span>
        </div>
        <div>
          <span>选定的内容02</span>
          <span style="color: #49D6FF;">￥100</span>
        </div>
        <div>
          <span>选定的内容03</span>
          <span style="color: #49D6FF;">￥200</span>
        </div>
        <div>
          <span>选定的内容04</span>
          <span style="color: #49D6FF;">￥300</span>
        </div>
      </div>
      <div class="order-total">
        <span>估价范围</span>
        <span style="color: #49D6FF;">￥5600~6000</span>
      </div>
    </div>
    <el-form ref="form" :model="form">
      <div class="setting-cont-list">
        <div class="list-title" style="margin-bottom: 0;">委托者信息</div>
        <el-form-item>
          <div class="list-input">
            <span class="input-txt">昵称<span style="color: #E9B96B;">*</span></span>
            <el-input class="name-input" v-model="input" placeholder="请输入名字"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input">
            <span class="input-txt">性别<span style="color: #E9B96B;">*</span></span>
            <el-select class="name-input" v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input">
            <span class="input-txt">联系方式<span style="color: #E9B96B;">*</span></span>
            <div class="she-choose">
              <div class="f-c" style="margin-right: 60px;">
                <div class="cont-radio">
                  <div class="cont-radio-checked" v-if="false"></div>
                </div>
                <span>邮箱</span>
              </div>
              <div class="f-c" style="margin-right: 60px;">
                <div class="cont-radio">
                  <div class="cont-radio-checked" v-if="false"></div>
                </div>
                <span>手机</span>
              </div>
              <div class="f-c" style="margin-right: 60px;">
                <div class="cont-radio">
                  <div class="cont-radio-checked" v-if="false"></div>
                </div>
                <span>社交网络</span>
              </div>
              <div class="f-c" style="margin-right: 60px;">
                <div class="cont-radio">
                  <div class="cont-radio-checked" v-if="false"></div>
                </div>
                <span>其他</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input">
            <span class="input-txt">社交网络<span style="color: #E9B96B;">*</span></span>
            <el-select class="name-input" v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input class="name-input" style="margin-left: 15px;" v-model="input" placeholder="微信号"></el-input>
          </div>
        </el-form-item>
      </div>
      <div class="setting-cont-list">
        <div class="list-title" style="margin-bottom: 0;">设定信息</div>
        <el-form-item>
          <div class="list-input">
            <span class="input-txt">角色名称</span>
            <el-input class="name-input" v-model="input" placeholder="请输入名字"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 20px;">
            <span class="input-txt">角色说明图</span>
          </div>
          <p class="img-txt">设定图与说明图</p>
          <p class="img-txt">支持 jpg, png, bmp, psd, tiff 等图片格式</p>
          <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple>
            <div class="el-upload__text">
              <p>点击选择图片</p>
              <p>（需小于10M）</p>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 20px;">
            <span class="input-txt">补充说明</span>
          </div>
          <el-input
            type="textarea"
            class="input-area"
            placeholder="输入内容"
            v-model="textarea"
            resize="none"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="setting-cont-list">
        <div class="list-title" style="margin-bottom: 0;">条款与合约</div>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 30px;">
            <span class="input-txt">关于本次委托申请单的填写，请您确认填写无误且想法是真实的<span style="color: #E9B96B;">*</span></span>
          </div>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我确认需求无误并是真实想法</span>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 30px;">
            <span class="input-txt">您是否具有完全民事行为能力</span>
          </div>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我已具有完全民事行为</span>
          </div>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我并没有完全民事行为能力（您可能需要提供监护人许可才能委托）</span>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 30px;">
            <span class="input-txt">如果您需要委托兽装制作，你需要确认您是否具有该设定的充分版权<span style="color: #E9B96B;">*</span></span>
          </div>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我确认我具有该设定的充分版权</span>
          </div>
          <p class="p-gray" style="margin-top: 30px;">（请您确保该设定的使用不会侵犯他人版权，若未如实填写，制作方将不对该侵权行为承担连带责任。）</p>
        </el-form-item>
        <el-form-item>
          <div class="list-input" style="margin-bottom: 30px;">
            <span class="input-txt">付款相关<span style="color: #E9B96B;">*</span></span>
          </div>
          <p class="p-gray" style="margin-bottom: 30px;">我们会在收到邮件后给您回复确认邮件和更详细的服务条款。如您确认委托，我们将会给你发送付款邮件。<br>
          我们采用收取定金+尾款的方式交易。<br>
          保留委托的定金是订单总价的10%，您在付款之后我们会将您列入排单队伍。<br>
          您需要再轮到你之前付款订单金额的50%，我们才会制作。制作完成之后再支付剩余尾款。（包括付款计划）<br>
          若中途取消委托，则该预付款无法退回。已经制作好的产品我们会进行改造再售处理。<br>
          详情请阅读<a href="#" style="text-decoration: underline;">《服务条款》</a>
          </p>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我同意该付款条款</span>
          </div>
          <div class="she-choose">
            <div class="cont-checkbox f-cc">
              <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
            </div>
            <span style="font-size: 25px;">我不同意该付款条款，需要详谈</span>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="setting-foot">
      <div style="margin-right: 20px;">上一页</div>
      <div>提交</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'principalInformation',
  data(){
    return{
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      textarea: '',
    }
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
<style lang="less" scoped>
.order-list{
  width: 1050px;
  margin: 0 auto;
  margin-bottom: 60px;
  .order-top{
    height: 80px;
    border-radius: 10px 10px 0px 0px;
    background: #3C4A74;
    display: flex;
    align-items: center;
    padding: 0 30px;
    img{
      width: 37px;
      height: 37px;
      margin-right: 15px;
    }
    span{
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .order-cont{
    div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 75px;
      padding: 0 30px;
      border: 1px solid #3C4A74;
      box-sizing: border-box;
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .order-total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding: 0 30px;
    border: 1px solid #3C4A74;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
    background: linear-gradient(270deg, rgba(80, 117, 207, 0.6) 0%, rgba(16, 16, 21, 0) 100%);
    border-radius: 0px 0px 10px 10px;
  }
}
.setting-cont-list{
  margin-bottom: 25px;
  .list-title{
    width: 618px;
    height: 80px;
    line-height: 80px;
    border-radius: 15px;
    background: linear-gradient(90deg, #5076D0 0%, rgba(80, 118, 208, 0) 99%), #24242D;
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
    padding-left: 25px;
    box-sizing: border-box;
    margin-bottom: 40px;
    span{
      color: #E9B96B;
    }
  }
  .list-cont{
    display: flex;
    .cont-she{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      height: 90px;
      border-radius: 15px;
      padding: 0 27px;
      margin-right: 30px;
      box-sizing: border-box;
      background: linear-gradient(90deg, #2E2E38 0%, rgba(80, 117, 207, 0.6) 100%);
      border: 3px solid #24242D;
      cursor: pointer;
      span{
        font-size: 30px;
        font-weight: 300;
        color: #ffffff;
      }
      &.active{
        border-color: #00C0FF;
      }
    }
    .cont-she:last-child{
      margin-right: 0;
    }
    .cont-big{
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 15px;
      padding: 27px;
      margin-right: 45px;
      box-sizing: border-box;
      background: linear-gradient(90deg, #2E2E38 0%, rgba(80, 117, 207, 0.6) 100%);
      border: 3px solid #24242D;
      margin-bottom: 25px;
      cursor: pointer;
      .she-img{
        height: 290px;
        width: 425px;
        margin: 0 auto;
        margin-bottom: 23px;
        div{
          width: 100%;
          height: 100%;
          background: #D8D8D8;
        }
      }
      &.active{
        border-color: #00C0FF;
      }
    }
    .cont-big:nth-child(2n){
      margin-right: 0;
    }
  }
  .list-input{
    margin-top: 40px;
    display: flex;
    align-items: center;
    .input-txt{
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
      margin-right: 24px;
    }
  }
}
.name-input{
  width: 285px;
  height: 80px;
}
:deep(.name-input .el-input__inner){
  height: 80px;
  line-height: 80px;
  background: #101015;
  border-color: #6A778B;
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
}
.el-form-item{
  margin-bottom: 0;
}
.she-choose{
  display: flex;
  align-items: center;
  span{
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
  }
}
.cont-radio{
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: #101015;
  box-sizing: border-box;
  border: 1px solid #6A778B;
  margin-right: 14px;
  .cont-radio-checked{
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 50%;
    background: #00C0FF;
  }
}
.f-c{
  display: flex;
  align-items: center;
}
.f-cc{
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-txt{
  font-size: 25px;
  font-weight: 300;
  color: #636378;
}
.upload-demo{
  width: 376px;
  height: 376px;
  border-radius: 5px;
  background: #101015;
  border: 1px dashed #6A778B;
  margin-top: 24px;
}
:deep(.el-upload){
  width: 100%;
  height: 100%;
}
:deep(.el-upload-dragger){
  width: 100%;
  height: 100%;
  background: #101015;
  border: 1px dashed #6A778B;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.el-upload__text{
  font-size: 30px;
  font-weight: 300;
  color: #414146;
  margin-bottom: 40px;
}
.input-area{
  width: 800px;
  height: 285px;
  border-radius: 5px;
  background: #101015;
  box-sizing: border-box;
  border: 1px solid #6A778B;
  font-size: 30px;
  font-weight: 300;
}
:deep(.el-textarea__inner){
  width: 100%;
  height: 100%;
  background: #101015;
}
.cont-checkbox{
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #101015;
  box-sizing: border-box;
  border: 1px solid #6A778B;
  margin-right: 25px;
  .cont-checkbox-checked{
    width: 24px;
    height: 24px;
  }
}
.p-gray{
  font-size: 25px;
  color: #636378;
  font-weight: 300;
}
.setting-foot{
  display: flex;
  justify-content: center;
  div{
    width: 235px;
    height: 75px;
    line-height: 75px;
    text-align: center;
    border-radius: 5px;
    margin-top: 60px;
    background: #5075CF;
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>