<template>
  <!-- sult估价 -->
  <div class="ways">
    <div class="ways-title">
      
    </div>
    <div class="setting-cont" v-if="false">
      <div class="setting-cont-list">
        <div class="list-title">设定 <span>*</span></div>
        <div class="list-cont">
          <div class="cont-she active">
            <div class="cont-radio">
              <div class="cont-radio-checked"></div>
            </div>
            <span>自带设定</span>
          </div>
          <div class="cont-she">
            <div class="cont-radio">
              <div class="cont-radio-checked" v-if="false"></div>
            </div>
            <span>领养角色</span>
          </div>
          <div class="cont-she">
            <div class="cont-radio">
              <div class="cont-radio-checked" v-if="false"></div>
            </div>
            <span>需委托设定<span style="color: #49D6FF;font-size: 26px;">+500</span></span>
          </div>
        </div>
        <div class="list-input">
          <span class="input-txt">角色编码 或 角色名称<span style="color: #E9B96B;">*</span></span>
          <el-input class="name-input" v-model="input" placeholder="请输入名字"></el-input>
        </div>
      </div>
      <div class="setting-cont-list">
        <div class="list-title">外壳版本 <span>*</span></div>
        <div class="list-cont">
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked"></div>
              </div>
              <span>标准外观 <span style="color: #49D6FF;">5000+</span></span>
            </div>
          </div>
          <div class="cont-big">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked" v-if="false"></div>
              </div>
              <span>自由外观 <span style="color: #49D6FF;">5000+</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-cont-list">
        <div class="list-title">耳机部件 <span>*</span></div>
        <div class="list-cont">
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked"></div>
              </div>
              <span>单色光片</span>
            </div>
          </div>
          <div class="cont-big">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked" v-if="false"></div>
              </div>
              <span>彩色屏幕 <span style="color: #49D6FF;">+300</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-cont-list">
        <div class="list-title">表情点阵 <span>*</span></div>
        <div class="list-cont">
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked"></div>
              </div>
              <span>圆形</span>
            </div>
          </div>
          <div class="cont-big">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-radio">
                <div class="cont-radio-checked" v-if="false"></div>
              </div>
              <span>方形 <span style="color: #49D6FF;">+100</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-cont-list">
        <div class="list-title">更多选配</div>
        <div class="list-cont" style="flex-wrap: wrap;">
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-checkbox">
                <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
              </div>
              <span>有角 <span style="color: #49D6FF;">+200</span></span>
            </div>
          </div>
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-checkbox">
                <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
              </div>
              <span>两对耳朵 <span style="color: #49D6FF;">+300</span></span>
            </div>
          </div>
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-checkbox">
                <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
              </div>
              <span>复杂花纹花色 <span style="color: #49D6FF;">+500</span></span>
            </div>
          </div>
          <div class="cont-big active">
            <div class="she-img">
              <div></div>
            </div>
            <div class="she-choose">
              <div class="cont-checkbox">
                <img class="cont-checkbox-checked" src="../../assets/img/point.png" alt="">
              </div>
              <span>彩色屏幕动画 <span style="color: #49D6FF;">+100</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-foot">下一页</div>
    </div>
    <div class="setting-cont">
      <PrincipalInformation></PrincipalInformation>
    </div>
  </div>
</template>
<script>
import PrincipalInformation from './principalInformation.vue';
export default {
  name: 'technologySetting',
  data(){
    return{
      input: '',
    }
  },
  components:{
    PrincipalInformation,
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
<style lang="less" scoped>
.ways{
  padding: 40px 0;
}
.ways-title{
  width: 100%;
  height: 500px;
  background: gray;
}
.setting-cont{
  max-width: 1200px;
  background: #24242D;
  margin: 0 auto;
  padding: 70px 80px;
  box-sizing: border-box;
}
.name-input{
  width: 285px;
  height: 80px;
}
:deep(.name-input .el-input__inner){
  height: 80px;
  line-height: 80px;
  background: #101015;
  border-color: #6A778B;
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
}
.cont-radio{
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: #101015;
  box-sizing: border-box;
  border: 1px solid #6A778B;
  margin-right: 20px;
  .cont-radio-checked{
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 50%;
    background: #00C0FF;
  }
}
.cont-checkbox{
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #101015;
  box-sizing: border-box;
  border: 1px solid #6A778B;
  margin-right: 25px;
  .cont-checkbox-checked{
    width: 24px;
    height: 24px;
    margin: 3px;
  }
}
.setting-foot{
  width: 235px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 5px;
  background: #5075CF;
  margin: 50px auto;
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
  cursor: pointer;
}
.setting-cont-list{
  margin-bottom: 25px;
  .list-title{
    width: 618px;
    height: 80px;
    line-height: 80px;
    border-radius: 15px;
    background: linear-gradient(90deg, #5076D0 0%, rgba(80, 118, 208, 0) 99%), #24242D;
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
    padding-left: 25px;
    box-sizing: border-box;
    margin-bottom: 40px;
    span{
      color: #E9B96B;
    }
  }
  .list-cont{
    display: flex;
    .cont-she{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      height: 90px;
      border-radius: 15px;
      padding: 0 27px;
      margin-right: 30px;
      box-sizing: border-box;
      background: linear-gradient(90deg, #2E2E38 0%, rgba(80, 117, 207, 0.6) 100%);
      border: 3px solid #24242D;
      cursor: pointer;
      span{
        font-size: 30px;
        font-weight: 300;
        color: #ffffff;
      }
      &.active{
        border-color: #00C0FF;
      }
    }
    .cont-she:last-child{
      margin-right: 0;
    }
    .cont-big{
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 15px;
      padding: 27px;
      margin-right: 45px;
      box-sizing: border-box;
      background: linear-gradient(90deg, #2E2E38 0%, rgba(80, 117, 207, 0.6) 100%);
      border: 3px solid #24242D;
      margin-bottom: 25px;
      cursor: pointer;
      .she-img{
        height: 290px;
        width: 425px;
        margin: 0 auto;
        margin-bottom: 23px;
        div{
          width: 100%;
          height: 100%;
          background: #D8D8D8;
        }
      }
      .she-choose{
        display: flex;
        align-items: center;
        span{
          font-size: 30px;
          font-weight: 300;
          color: #ffffff;
        }
      }
      &.active{
        border-color: #00C0FF;
      }
    }
    .cont-big:nth-child(2n){
      margin-right: 0;
    }
  }
  .list-input{
    margin-top: 40px;
    display: flex;
    align-items: center;
    .input-txt{
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
      margin-right: 24px;
    }
  }
}
</style>